// src/components/UnderConstruction.js
import React from 'react';
import './UnderConstruction.css';

const UnderConstruction = () => {
  return (
    <div className="container">
      <div className="content">
        <h1>🚧 Under Construction 🚧</h1>
        <p>Η ιστοσελίδα μας βρίσκεται υπό κατασκευή! 
            <br></br>Για οποιαδήποτε πληροφορία μπορείτε να καλέσετε στο 210 5125100.
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;
